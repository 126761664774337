const API_BASE_URL = 'https://alpina.hotel-alp-bovec.com/api/assistant';

const handleResponse = async (response) => {
  if (!response.ok) {
    throw new Error('Failed to fetch');
  }
  return response.json();
};

export const fetchAssistantResponseStream = async (question, threadId, onChunkReceived) => {
  try {
    const response = await fetch(`${API_BASE_URL}/thread`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Origin': 'https://alpina.hotel-alp-bovec.com'
      },
      body: JSON.stringify({ question, threadId }),
      credentials: 'include', // Ensure cookies are sent
    });

    if (response.body) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let result;
      while (!(result = await reader.read()).done) {
        const chunk = decoder.decode(result.value, { stream: true });
        // console.log('Received chunk:', chunk);
        onChunkReceived(chunk);
      }
    } else {
      throw new Error('No response body');
    }

    return response.headers.get('X-Thread-ID') || threadId;
  } catch (error) {
    console.error('Error fetching assistant response stream:', error);
    throw error;
  }
};

export const fetchThreadMessages = async (threadId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/thread/${threadId}/messages`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Origin': 'https://alpina.hotel-alp-bovec.com'
      },
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error('Failed to fetch thread messages');
    }

    return handleResponse(response);
  } catch (error) {
    console.error('Error fetching thread messages:', error);
    throw error;
  }
};

export const createNewThread = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/thread/new`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Origin': 'https://alpina.hotel-alp-bovec.com'
      },
      credentials: 'include', // Ensure cookies are sent
    });

    if (!response.ok) {
      throw new Error('Failed to create new thread');
    }

    return handleResponse(response);
  } catch (error) {
    console.error('Error creating new thread:', error);
    throw error;
  }
};
