import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { NavigationProvider } from './components/NavigationContext';
import Chat from './pages/Chat/Chat';
import Privacy from './pages/SubPage/Privacy';
import GettingStarted from './pages/SubPage/HowToUseAlpina';
import MeetAlpina from './pages/SubPage/AboutAlpina';
import Sources from './pages/SubPage/Sources'; // Uvoz nove podstrani
import NotFound from './pages/SubPage/NotFound';
import MenuPage from './pages/Menu/MenuPage';
import './App.css';

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route exact path="/" element={<Chat />} />
        <Route path="/menu" element={<MenuPage />} />
        <Route path="/getting-started" element={<GettingStarted />} />
        <Route path="/meet-alpina" element={<MeetAlpina />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/sources" element={<Sources />} /> {/* Nova pot */}
        <Route path="*" element={<NotFound />} /> 
      </Routes>
    </AnimatePresence>
  );
}

function App() {
  return (
    <NavigationProvider>
      <Router>
        <div className="App">
          <AnimatedRoutes />
        </div>
      </Router>
    </NavigationProvider>
  );
}

export default App;
