import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import Header from '../../components/HeaderSubPage/HeaderSubPage';
import './SubPage.css';

const NotFound = () => {
  const { t } = useTranslation(); // Initialize useTranslation

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on mount
  }, []);

  return (
    <motion.div
      className="subpage"
      initial={{ opacity: 0, y: -1}}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className="subpage-container">
        <Header />  
        <div className="subpage-content">
          <h2>{t('pageNotFound')}</h2>
          <p>{t('pageNotFoundMessage')}</p>
        </div>
      </div>
    </motion.div>
  );
};

export default NotFound;


