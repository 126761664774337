import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AlpinaImage from '../../assets/img/Alpina.png';
import './Header.css';

const Header = ({ onResetChat }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuToggle = () => {
    navigate('/menu', { state: { from: location.pathname } });
  };

  return (
      <div className="chat-header">
        <img src={AlpinaImage} alt="Ana AI" className="avatar" />
        <h2>Alpina AI</h2>
        <button className="menu-button" aria-label="Open menu" onClick={handleMenuToggle}>
          <i className="fas fa-bars"></i>
        </button>
      </div>
  );
};

export default Header;
