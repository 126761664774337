import { useState, useEffect, useRef, useCallback } from 'react';
import { fetchAssistantResponseStream, fetchThreadMessages } from '../services/api'; //add createNewThread
import Cookies from 'js-cookie';

export const useAssistant = () => {
  const [question, setQuestion] = useState('');
  const [error, setError] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const chatEndRef = useRef(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);

  const isSafariOnMac = () => {
    const ua = navigator.userAgent;
    return ua.includes('Safari') && !ua.includes('Chrome') && ua.includes('Macintosh');
  };

  const scrollToBottom = useCallback(() => {
    if (chatEndRef.current) {
      const behavior = isSafariOnMac() ? 'auto' : 'smooth';
      chatEndRef.current.scrollIntoView({ behavior });
    }
  }, []);

  const fetchChatHistory = useCallback(async (threadId) => {
    try {
      const messages = await fetchThreadMessages(threadId);
      messages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      setChatHistory(messages);
      scrollToBottom();
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  }, [scrollToBottom]);

  useEffect(() => {
    const threadId = Cookies.get('threadId');
    if (threadId) {
      fetchChatHistory(threadId);
    }
  }, [fetchChatHistory]);

  useEffect(() => {
    if (!isUserScrolling) {
      scrollToBottom();
    }
  }, [chatHistory, isUserScrolling, scrollToBottom]);

  const handleUserScroll = () => {
    if (chatEndRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatEndRef.current.parentNode;
      setIsUserScrolling(scrollHeight - scrollTop !== clientHeight);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (!question.trim()) {
      // setError('Please enter a valid question.');
      return;
    }
    await processMessage(question);
  };

  const handleRecommendationSubmit = async (rec) => {
    await processMessage(rec);
  };

  const processMessage = async (message) => {
    if (isProcessing) {
      setError('Please wait for the current request to complete.');
      return;
    }

    const threadId = Cookies.get('threadId');
    console.log('threadId from js-cookie at form submit:', threadId);

    setError('');
    setIsProcessing(true);

    try {
      let fullResponse = '';

      const userMessage = { role: 'user', content: [{ type: 'text', text: { value: message } }] };
      setChatHistory(prevHistory => [...prevHistory, userMessage]);
      scrollToBottom();

      const assistantMessage = { role: 'assistant', content: [{ type: 'text', text: { value: '' } }] };
      setChatHistory(prevHistory => [...prevHistory, assistantMessage]);

      await fetchAssistantResponseStream(message, threadId, (chunk) => {
        fullResponse += chunk;
        setChatHistory(prevHistory => {
          const updatedHistory = [...prevHistory];
          updatedHistory[updatedHistory.length - 1].content[0].text.value = fullResponse;
          return updatedHistory;
        });
        scrollToBottom();
      });

    } catch (err) {
      setError(`Failed to fetch response: ${err.message}`);
    } finally {
      setIsProcessing(false);
      scrollToBottom();
    }

    setQuestion('');
  };

  return {
    question,
    setQuestion,
    error,
    chatHistory,
    isProcessing,
    handleFormSubmit,
    handleRecommendationSubmit,
    chatEndRef,
    handleUserScroll
  };
};
