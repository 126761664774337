import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Header from '../../components/HeaderSubPage/HeaderSubPage';
import { FaSmile, FaHandsHelping, FaInfoCircle, FaComments } from 'react-icons/fa'; 
import './SubPage.css';

const MeetAlpina = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      className="subpage"
      initial={{ opacity: 0, y: -1}}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className="subpage-container">
        <Header />
        <div className="subpage-content">
          <div className="content-section">
            <FaSmile className="content-icon" />
            <h3>{t('aboutAlpina.whoAmITitle')}</h3>
            <p>{t('aboutAlpina.whoAmIText')}</p>
          </div>
          <div className="content-section">
            <FaHandsHelping className="content-icon" />
            <h3>{t('aboutAlpina.howCanIHelpTitle')}</h3>
            <p>{t('aboutAlpina.howCanIHelpText')}</p>
          </div>
          <div className="content-section">
            <FaInfoCircle className="content-icon" />
            <h3>{t('aboutAlpina.funFactTitle')}</h3>
            <p>{t('aboutAlpina.funFactText')}</p>
          </div>
          <div className="content-section">
            <FaComments className="content-icon" />
            <h3>{t('aboutAlpina.getStartedTitle')}</h3>
            <p>{t('aboutAlpina.getStartedText')}</p>
          </div>
          <div className="content-section">
            <h4>{t('aboutAlpina.disclaimerTitle')}</h4>
            <p>{t('aboutAlpina.disclaimerText')}</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default MeetAlpina;
