// src/components/NavigationContext.js
import React, { createContext, useContext, useState } from 'react';

const NavigationContext = createContext();

export const useNavigation = () => useContext(NavigationContext);

export const NavigationProvider = ({ children }) => {
  const [prevPath, setPrevPath] = useState('/');

  return (
    <NavigationContext.Provider value={{ prevPath, setPrevPath }}>
      {children}
    </NavigationContext.Provider>
  );
};
