import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import LogoImage from '../../assets/img/logoalp.png';
import AlpinaImage from '../../assets/img/Alpina.png';
import './ChatHistory.css';

const ChatHistory = ({ chatHistory, chatEndRef, handleUserScroll }) => {
  const { t } = useTranslation();

  return (
    <div className="chat-history" onScroll={handleUserScroll}>
      <div className="assistant-info">
        <picture>
          <source srcSet={LogoImage} type="image/png" />
          <img src={LogoImage} alt="ALP hotel" className="assistant-avatar" />
        </picture>
        <h2>Alpina AI</h2>
        <p>{t('welcome')}</p>
      </div>
      {chatHistory.map((message, index) => (
        <div key={index} className={`chat-message ${message.role}`}>
          {message.role === 'assistant' && <img src={AlpinaImage} alt="Avatar" className="avatar" />}
          <div className="message-content">
            <ReactMarkdown>{message.content[0].text.value}</ReactMarkdown>
          </div>
        </div>
      ))}
      <div ref={chatEndRef}></div>
    </div>
  );
};

export default ChatHistory;
