import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Header from '../../components/HeaderSubPage/HeaderSubPage';
import { FaUserSecret, FaUserShield, FaShareAlt, FaGavel, FaEnvelope, FaCookieBite } from 'react-icons/fa'; // Import icons
import './SubPage.css';

const Privacy = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      className="subpage"
      initial={{ opacity: 0, y: -1 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className="subpage-container">
        <Header />
        <div className="subpage-content">
          <div className="content-section">
            <p>{t('privacy.intro')}</p>
          </div>
          <div className="content-section">
            <div className="icon-container">
              <FaUserSecret className="content-icon-privacy" />
            </div>
            <h3>{t('privacy.infoCollectTitle')}</h3>
            <p className="multiline-text">{t('privacy.infoCollectText')}</p>
          </div>
          <div className="content-section">
            <div className="icon-container">
              <FaUserShield className="content-icon-privacy" />
            </div>
            <h3>{t('privacy.infoUseTitle')}</h3>
            <p className="multiline-text">{t('privacy.infoUseText')}</p>
          </div>
          <div className="content-section">
            <div className="icon-container">
              <FaShareAlt className="content-icon-privacy" />
            </div>
            <h3>{t('privacy.infoShareTitle')}</h3>
            <p>{t('privacy.infoShareText')}</p>
          </div>
          <div className="content-section">
            <div className="icon-container">
              <FaGavel className="content-icon-privacy" />
            </div>
            <h3>{t('privacy.changesTitle')}</h3>
            <p>{t('privacy.changesText')}</p>
          </div>
          <div className="content-section">
            <div className="icon-container">
              <FaEnvelope className="content-icon-privacy" />
            </div>
            <h3>{t('privacy.contactTitle')}</h3>
            <p>{t('privacy.contactText')}</p>
          </div>
          <div className="content-section">
            <div className="icon-container">
              <FaCookieBite className="content-icon-privacy" />
            </div>
            <h3>{t('privacy.cookiesTitle')}</h3>
            <p className="multiline-text">{t('privacy.cookiesText')}</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Privacy;
