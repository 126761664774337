import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useNavigation } from '../../components/NavigationContext';
import LogoImage from '../../assets/img/logo.svg';
import AlpinaImage from '../../assets/img/Alpina.png';
import SlImage from '../../assets/img/sl.png';
import UkImage from '../../assets/img/uk.png';
import './MenuPage.css';

const MenuPage = ({ onResetChat }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { prevPath, setPrevPath } = useNavigation();
  const { t, i18n } = useTranslation();
  const [activeLang, setActiveLang] = useState(i18n.language);

  useEffect(() => {
    if (location.state && location.state.from) {
      setPrevPath(location.state.from);
    } else if (location.pathname !== '/menu') {
      setPrevPath(location.pathname);
    }
  }, [location.pathname, location.state, setPrevPath]);

  const handleNavigation = (path) => {
    navigate(path, { state: { from: location.pathname } });
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLang(lng); // Set active language
  };

  return (
    <motion.div
      className="menu-page"
      initial={{ opacity: 0, y: -1 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className="menu-sidebar">
        <div className="menu-header">
          <img src={AlpinaImage} alt="Alpina AI" className="menu-avatar" onClick={() => handleNavigation('/')} />
          <h2 className="menu-title" onClick={() => handleNavigation('/')}>Alpina AI</h2>
          <button className="menu-close-button" onClick={() => handleNavigation('/')}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="menu-options">
          <button
            className={`menu-button-option ${prevPath === '/' ? 'active' : ''}`}
            onClick={() => handleNavigation('/')}
            aria-label="Open home"
          >
            <i className="fa-solid fa-house-chimney"></i> {t('chatWithAlpina')}
          </button>
          <button
            className={`menu-button-option ${prevPath === '/getting-started' ? 'active' : ''}`}
            onClick={() => handleNavigation('/getting-started')}
            aria-label="Open getting started"
          >
            <i className="fa-solid fa-handshake-angle"></i> {t('howToUseAlpinaButton')}
          </button>
          <button
            className={`menu-button-option ${prevPath === '/meet-alpina' ? 'active' : ''}`}
            onClick={() => handleNavigation('/meet-alpina')}
            aria-label="Open meet alpina"
          >
            <i className="fa-solid fa-address-card"></i> {t('aboutAlpinaButton')}
          </button>
          <button
            className={`menu-button-option ${prevPath === '/sources' ? 'active' : ''}`}  // Dodaj novo možnost za vire
            onClick={() => handleNavigation('/sources')}
            aria-label="Open sources"
          >
            <i className="fa-solid fa-book"></i> {t('sourcesButton')}
          </button>
          <button
            className={`menu-button-option ${prevPath === '/privacy' ? 'active' : ''}`}
            onClick={() => handleNavigation('/privacy')}
            aria-label="Open privacy"
          >
            <i className="fa-solid fa-lock"></i> {t('privacyButton')}
          </button>
          <div className="language-switcher">
            <button
              className={`lang-button ${activeLang === 'en' ? 'active' : ''}`}
              onClick={() => changeLanguage('en')}
              aria-label="Change language EN"
            >
              <img src={UkImage} alt="English" className="flag-icon" /> {t('changeLangEn')}
            </button>
            <button
              className={`lang-button ${activeLang === 'sl' ? 'active' : ''}`}
              onClick={() => changeLanguage('sl')}
              aria-label="Change language EN"
            >
              <img src={SlImage} alt="Slovenian" className="flag-icon" /> {t('changeLangSl')}
            </button>
          </div>
          <div className="menu-footer">
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default MenuPage;
