import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useAssistant } from '../../hooks/useAssistant';
import ChatHistory from '../../components/ChatHistroy/ChatHistory';
import WelcomeModal from '../../components/WelcomeModal/WelcomeModal';
import Recommendations from '../../components/Recommendations/Recommendations';
import Header from '../../components/Header/Header';
import './Chat.css';
import Cookies from 'js-cookie';

const Chat = () => {
  const { t } = useTranslation();
  const { question, setQuestion, error, chatHistory, isProcessing, handleFormSubmit, handleRecommendationSubmit, chatEndRef, handleUserScroll, resetChat } = useAssistant();
  const inputRef = useRef(null);
  const [showWelcomeModal, setShowWelcomeModal] = useState(true);
  const [showStartNewChat, setShowStartNewChat] = useState(false);
  const [showRecommendations, setShowRecommendations] = useState(false);

  useEffect(() => {
    const hideWelcomeModal = Cookies.get('hideWelcomeModal');
    const threadId = Cookies.get('threadId');
    if (hideWelcomeModal) {
      setShowWelcomeModal(false);
    }
    if (!threadId) {
      setShowStartNewChat(true);
    }
  }, []);

  const handleWelcomeModalClose = () => {
    const hideCheckbox = document.querySelector('.welcome-modal-checkbox input');
    if (hideCheckbox && hideCheckbox.checked) {
      Cookies.set('hideWelcomeModal', 'true', { expires: 7 });
    }
    setShowWelcomeModal(false);
  };

  const handleStartNewChat = () => {
    setShowStartNewChat(false);
    const recommendationDelay = setTimeout(() => {
      setShowRecommendations(true);
    }, 2000);

  
    return () => clearTimeout(recommendationDelay); // Clean up the timeout if the component unmounts
  };

  const handleInputChange = (e) => {
    setQuestion(e.target.value);
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleFormSubmitWithReset(e);
    }
  };

  const handleFormSubmitWithReset = (e) => {
    setShowRecommendations(false);
    handleFormSubmit(e);
    if (inputRef.current) {
      inputRef.current.style.height = 'auto';
    }
  };

  const handleRecommendationSelect = (rec) => {
    setShowRecommendations(false);
    handleRecommendationSubmit(rec);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -1 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
      className="chat-container"
    >
      {showWelcomeModal && <WelcomeModal onClose={handleWelcomeModalClose} />}
      <Header onResetChat={resetChat} />
      <ChatHistory chatHistory={chatHistory} chatEndRef={chatEndRef} handleUserScroll={handleUserScroll} />
      {showRecommendations && <Recommendations onSelect={handleRecommendationSelect} />}
      {showStartNewChat && (
        <div className="start-new-chat-container">
          <button className="start-new-chat-button" onClick={handleStartNewChat}>
            {t('startConversation')}
          </button>
        </div>
      )}
      {!showStartNewChat && (
        <div className="chat-footer">
          <form onSubmit={handleFormSubmitWithReset} className="question-form">
            <textarea
              ref={inputRef}
              value={question}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder={t('messagePlaceholder')}
              required
              className="question-input"
              disabled={isProcessing}
              rows="1"
            />
            <button type="submit" className="submit-button" disabled={!question || isProcessing}>
              <i className="fas fa-arrow-up"></i>
            </button>
          </form>
          {error && <div className="error-message">{error}</div>}
        </div>
      )}
    </motion.div>
  );
};

export default Chat;
