import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './WelcomeModal.css';
import SlImage from '../../assets/img/sl.png';
import UkImage from '../../assets/img/uk.png';
import AlpinaImagePng from '../../assets/img/Alpina.png'; // PNG verzija

const WelcomeModal = ({ onClose }) => {
  const { t, i18n } = useTranslation();
  const [activeLang, setActiveLang] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setActiveLang(lng);
  };

  return (
    <div className="welcome-modal-overlay">
      <div className="welcome-modal">
        <picture>
          <source srcSet={AlpinaImagePng} type="image/png" />
          <img src={AlpinaImagePng} alt="Ana AI" className="avatar-modal" />
        </picture>
        <h2>Alpina AI</h2>
        <h5>{t('chooseLangMessage')}</h5>
        <div className="language-switcher-modal">
          <button
            className={`lang-button-modal ${activeLang === 'en' ? 'active' : ''}`}
            onClick={() => changeLanguage('en')}
            aria-label="Switch to English"
          >
            <img src={UkImage} alt="United Kingdom flag" className="flag-icon-modal" /> {t('changeLangEn')}
          </button>
          <button
            className={`lang-button-modal ${activeLang === 'sl' ? 'active' : ''}`}
            onClick={() => changeLanguage('sl')}
            aria-label="Switch to Slovenian"
          >
            <img src={SlImage} alt="Slovenian flag" className="flag-icon-modal" /> {t('changeLangSl')}
          </button>
        </div>
        <p>{t('welcomeMessage')} <b>{t('disclaimer')}</b></p>
        <div className="welcome-modal-footer">
          <p>{t('cookieNotice')} <a href="/privacy">{t('learnMore')}</a></p>
          <button className="welcome-modal-button" onClick={onClose} aria-label="Close welcome modal">
            {t('iUnderstand')}
          </button>
          <div className="welcome-modal-checkbox">
            <label>
              <input type="checkbox" defaultChecked /> {t('dontShowAgain')}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal;
