import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Header from '../../components/HeaderSubPage/HeaderSubPage';
import './SubPage.css';

const Sources = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      className="subpage"
      initial={{ opacity: 0, y: -1 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className="subpage-container" style={{ textAlign: 'left' }}>
        <Header />
        <div className="subpage-content">
          <div className="content-section">
            <p>{t('sources.specificText')} <a href="https://www.soca-valley.com" target="_blank" rel="noopener noreferrer">{t('sources.socaValleyLink')}</a>.</p>
                <ul>
                  <li>
                    <a href="https://www.soca-valley.com/sl/dolina-soce/" target="_blank" rel="noopener noreferrer">
                      https://www.soca-valley.com/sl/dolina-soce/
                    </a>
                  </li>
                  <li>
                    <a href="https://www.soca-valley.com/sl/dolina-soce/kraji-v-dolini/" target="_blank" rel="noopener noreferrer">
                      https://www.soca-valley.com/sl/dolina-soce/kraji-v-dolini/
                    </a>
                  </li>
                  <li>
                    <a href="https://www.soca-valley.com/sl/dolina-soce/kako-do-nas/" target="_blank" rel="noopener noreferrer">
                      https://www.soca-valley.com/sl/dolina-soce/kako-do-nas/
                    </a>
                  </li>
                  <li>
                    <a href="https://www.soca-valley.com/sl/dolina-soce/trajnostna-mobilnost/" target="_blank" rel="noopener noreferrer">
                      https://www.soca-valley.com/sl/dolina-soce/trajnostna-mobilnost/
                    </a>
                  </li>
                  <li>
                    <a href="https://www.soca-valley.com/sl/dolina-soce/vreme/" target="_blank" rel="noopener noreferrer">
                      https://www.soca-valley.com/sl/dolina-soce/vreme/
                    </a>
                  </li>
                  <li>
                    <a href="https://www.soca-valley.com/sl/dolina-soce/dogodki/" target="_blank" rel="noopener noreferrer">
                      https://www.soca-valley.com/sl/dolina-soce/dogodki/
                    </a>
                  </li>
                  <li>
                    <a href="https://www.soca-valley.com/sl/iskanje-dogodivscin/narava/" target="_blank" rel="noopener noreferrer">
                      https://www.soca-valley.com/sl/iskanje-dogodivscin/narava/
                    </a>
                  </li>
                  <li>
                    <a href="https://www.soca-valley.com/sl/iskanje-dogodivscin/kultura/" target="_blank" rel="noopener noreferrer">
                      https://www.soca-valley.com/sl/iskanje-dogodivscin/kultura/
                    </a>
                  </li>
                </ul>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Sources;
