import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import './HeaderSubPage.css';

const HeaderSubPage = ({ onResetChat }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(); // Initialize useTranslation

  const handleMenuToggle = () => {
    navigate('/menu', { state: { from: location.pathname } });
  };

  const handleBackNavigation = () => {
    navigate('/');
  };

  // Determine the title based on the current pathname
  const getTitle = () => {
    switch (location.pathname) {
      case '/getting-started':
        return t('howToUseAlpinaButton');
      case '/meet-alpina':
        return t('aboutAlpinaButton');
      case '/privacy':
        return t('privacyButton');
      case '/sources':
        return t('sourcesButton');
      default:
        return t('pageNotFound');
    }
  };

  return (
    <div className="chat-header-subpage">
      <button className="button-subpage" onClick={handleBackNavigation}>
        <i className="fa-solid fa-arrow-left"></i>
      </button>
      <h2>{getTitle()}</h2>
      <button className="button-subpage" onClick={handleMenuToggle}>
        <i className="fas fa-bars"></i>
      </button>
    </div>
  );
};

export default HeaderSubPage;
