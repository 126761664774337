import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Header from '../../components/HeaderSubPage/HeaderSubPage';
import { FaComments, FaQuestionCircle, FaCheckCircle, FaHandsHelping } from 'react-icons/fa'; 
import './SubPage.css';

const GettingStarted = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      className="subpage"
      initial={{ opacity: 0, y: -1}}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2 }}
    >
      <div className="subpage-container">
        <Header />
        <div className="subpage-content">
          <div className="content-section">
            <p>{t('howToUseAlpina.welcome')}</p>
          </div>
          <div className="content-section">
            <FaComments className="content-icon" />
            <h3>{t('howToUseAlpina.step1Title')}</h3>
            <p>{t('howToUseAlpina.step1Text')}</p>
          </div>
          <div className="content-section">
            <FaQuestionCircle className="content-icon" />
            <h3>{t('howToUseAlpina.step2Title')}</h3>
            <p>{t('howToUseAlpina.step2Text')}</p>
          </div>
          <div className="content-section">
            <FaCheckCircle className="content-icon" />
            <h3>{t('howToUseAlpina.step3Title')}</h3>
            <p>{t('howToUseAlpina.step3Text')}</p>
          </div>
          <div className="content-section">
            <FaHandsHelping className="content-icon" />
            <h3>{t('howToUseAlpina.step4Title')}</h3>
            <p>{t('howToUseAlpina.step4Text')}</p>
          </div>
          <div className="content-section">
            <h4>{t('aboutAlpina.disclaimerTitle')}</h4>
            <p>{t('aboutAlpina.disclaimerText')}</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default GettingStarted;
