import React from 'react';
import { useTranslation } from 'react-i18next';
import './Recommendations.css';

const Recommendations = ({ onSelect }) => {
  const { t } = useTranslation();
  const recommendations = [
    { title: t('recommendationTitle1'), description: t('recommendationDescription1'), query: t('recommendationQuery1') },
    { title: t('recommendationTitle2'), description: t('recommendationDescription2'), query: t('recommendationQuery2') },
    { title: t('recommendationTitle3'), description: t('recommendationDescription3'), query: t('recommendationQuery3') },
    { title: t('recommendationTitle4'), description: t('recommendationDescription4'), query: t('recommendationQuery4') },
  ];

  return (
    <div className="recommendations-container">
      {recommendations.map((rec, index) => (
        <button key={index} className="recommendation-button" onClick={() => onSelect(rec.query)}>
          <strong>{rec.title}</strong>
          <span>{rec.description}</span>
        </button>
      ))}
    </div>
  );
};

export default Recommendations;
